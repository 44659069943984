<!--
// nuxt-ui/components/layout/NavCompetition.vue
-->
<script setup lang="ts">
import type { SocialMedia } from "~/src/region/domain/models/Region";
import type { Menu } from "~/src/region/domain/models/Menu";
import { useWebContext } from "~/nuxt-ui/Shared/composables/webContext";
import { useCompetitionStore } from "~/nuxt-ui/stores/competitionStore";
import { useRegions } from "~/nuxt-ui/Shared/composables/regions";
import { useWebappStore } from "~/nuxt-ui/stores/webappStore";
import { useLinkUtils } from "~/nuxt-ui/Shared/composables/links";
import { initDropdowns } from "flowbite";
import ButtonsNavbar from "~/nuxt-ui/components/layout/header/ButtonsNavbar.vue";
import RegionMenus from "~/src/region/datasources/navigationMenu.json";

type Props = {
  twitchIsLive: boolean;
};

const props = defineProps<Props>();

const localePath = useLocalePath();
const { isKings, webContext, isQueens } = useWebContext();
const competitionStore = useCompetitionStore();
const { isSpain, isAmericas, isWorldCup, isItaly } = useRegions();
const { locale } = useI18n();
const route = useRoute();
const webappStore = useWebappStore();
const { setLinkTo } = useLinkUtils();

const socialMedia: SocialMedia[] = competitionStore.region?.social[webContext] || [];
const liveButtonUrl: string | undefined =
  isWorldCup && locale.value === "es"
    ? "https://www.twitch.tv/kingsleague"
    : socialMedia.find(s => s.name === "twitch")?.url;

const showRegionCompetitionMenu = (regionSlug: string): boolean => {
  if (!webappStore.hoveringMegaMenu) {
    return webappStore.activeRegion === regionSlug;
  } else {
    return webappStore.regionHovered === regionSlug;
  }
};

const navMenus: Menu[] = RegionMenus[webContext];

onMounted(() => {
  initDropdowns();
});
</script>

<template>
  <div class="hidden lg:flex w-full justify-between items-center">
    <nav id="navbar-competition">
      <ul
        v-for="(menu, i) in navMenus"
        :key="i"
        class="flex lg:space-x-6 text-white text-sm uppercase"
        v-show="showRegionCompetitionMenu(menu.region)"
        @mouseover="webappStore.navCompetitionHovered = menu.region"
        @mouseleave="webappStore.navCompetitionHovered = undefined"
      >
        <li class="nav-link" v-for="menuItem in menu.children" :key="menuItem.name">
          <div v-if="!!menuItem.children">
            <button
              :id="`${menuItem.name}Navbar${menu.region}`"
              :data-dropdown-toggle="`dropdown${menuItem.name}Navbar${menu.region}`"
              data-dropdown-trigger="hover"
              class="nav-link inline-flex items-center uppercase"
              type="button"
            >
              {{ $t(`header.navCompetition.${menuItem.name}`) }}
              <svg
                class="w-2 h-2 ml-1"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 10 6"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 1 4 4 4-4"
                />
              </svg>
            </button>
            <!-- Dropdown menu -->
            <div
              :id="`dropdown${menuItem.name}Navbar${menu.region}`"
              class="z-30 hidden rounded-lg shadow text-left bg-gray-900 border border-gray-700 py-2 w-[262px]"
            >
              <ul class="text-sm text-gray-400" :aria-labelledby="`${menuItem.name}Navbar${menu.region}`">
                <li class="subitem-dropdown" v-for="subItem in menuItem.children">
                  <nuxt-link
                    no-prefetch
                    :to="setLinkTo(subItem.link, menu.region)"
                    :external="menu.region !== route.params.region"
                    class="subitem-link-navbar"
                    @click="webappStore.activeRegion = menu.region"
                  >
                    {{ $t(`header.navCompetition.${subItem.name}`) }}
                  </nuxt-link>
                </li>
              </ul>
            </div>
          </div>

          <nuxt-link
            v-else-if="menuItem.name === 'fantasy'"
            no-prefetch
            :to="menuItem.link"
            external
            target="_blank"
          >
            <span class="flex items-center gap-1">
              <!--              F icon-->
              <svg
                width="auto"
                height="12"
                viewBox="0 0 345 341"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M122.9 226.4H213.7L262.5 141.9H122.9V84.5H295.6L344.4 0H0.799805V302.5L115.3 340.1L122.9 337.6V226.4Z"
                  fill="currentColor"
                />
              </svg>
              {{ $t(`header.navCompetition.${menuItem.name}`) }}
            </span>
          </nuxt-link>

          <nuxt-link
            v-else
            :to="localePath({ name: menuItem.link, params: { region: menu.region } })"
            :no-prefetch="
              menuItem.name !== 'teams' || menuItem.name !== 'matches' || menuItem.name !== 'tables'
            "
            :external="menu.region !== route.params.region"
            @click="webappStore.activeRegion = menu.region"
          >
            {{ $t(`header.navCompetition.${menuItem.name}`) }}
          </nuxt-link>
        </li>
      </ul>
    </nav>
    <div class="flex space-x-2">
      <buttons-navbar
        v-if="!isItaly"
        live
        tickets
        :store="!isItaly"
        :store-url="competitionStore.region?.storeUrl"
        :live-url="liveButtonUrl"
        :twitch-is-live="twitchIsLive"
      ></buttons-navbar>
    </div>
  </div>
</template>

<style scoped></style>
