<!--
// nuxt-ui/components/layout/TheFooter.vue
-->
<script setup lang="ts">
import type { Menu } from "~/src/region/domain/models/Menu";
import SocialIcons from "~/nuxt-ui/components/Shared/SocialIcons.vue";
import MainGradientDivider from "~/nuxt-ui/components/context-shared/MainGradientDivider.vue";
import BannerCompetitionName from "~/nuxt-ui/components/context-shared/BannerCompetitionName.vue";
import GridTeamsShields from "~/nuxt-ui/components/layout/footer/GridTeamsShields.vue";
import ContextOtherLeaguesLogo from "~/nuxt-ui/components/context-shared/ContextOtherLeaguesLogo.vue";
import ContextLogoLeagueFooter from "~/nuxt-ui/components/context-shared/ContextLogoLeagueFooter.vue";
import RegionFooterMenus from "~/src/region/datasources/footerMenu.json";
import { useWebContext } from "~/nuxt-ui/Shared/composables/webContext";
import { useCompetitionStore } from "~/nuxt-ui/stores/competitionStore";
import { useRegions } from "~/nuxt-ui/Shared/composables/regions";
import { useLinkUtils } from "~/nuxt-ui/Shared/composables/links";

const { region } = useCompetitionStore();
const { isKings, isQueens, webContext, contextSiteTitle } = useWebContext();
const { isItaly, isAmericas } = useRegions();
const { setLinkTo } = useLinkUtils();

const regionSocial = region?.social[webContext];
const socialProfiles = {
  twitter: regionSocial ? regionSocial?.find(s => s.name === "twitter")?.url : undefined,
  discord: regionSocial ? regionSocial.find(s => s.name === "discord")?.url : undefined,
  instagram: regionSocial ? regionSocial.find(s => s.name === "instagram")?.url : undefined,
  twitch: regionSocial ? regionSocial.find(s => s.name === "twitch")?.url : undefined,
  tiktok: regionSocial ? regionSocial.find(s => s.name === "tiktok")?.url : undefined,
  youtube: regionSocial ? regionSocial.find(s => s.name === "youtube")?.url : undefined,
};

const actualYear = new Date().getFullYear();

const footerMenus: Menu[] = RegionFooterMenus[webContext];
const footerNavString = ["news", "contact", "regulation", "careers"];
</script>

<template>
  <footer>
    <main-gradient-divider height="h-2.5"></main-gradient-divider>
    <banner-competition-name></banner-competition-name>
    <grid-teams-shields v-if="(!isItaly && isKings) || (isQueens && !isAmericas)"></grid-teams-shields>
    <div class="kql-w-container py-10 px-4 md:px-6">
      <div class="container-items-footer">
        <div>
          <div class="flex flex-col items-start justify-between gap-y-5">
            <context-logo-league-footer></context-logo-league-footer>
            <social-icons :social-profiles="socialProfiles"></social-icons>
          </div>
        </div>
        <div class="mt-8 md:mt-0 flex items-start justify-start md:justify-end">
          <ul
            v-for="(menu, i) in footerMenus"
            :key="i"
            v-show="region && menu.region === region.slug"
            class="nav-footer"
          >
            <li v-for="menuItem in menu.children">
              <nuxt-link
                :to="setLinkTo(menuItem.link, menu.region)"
                :no-prefetch="
                  menuItem.name !== 'teams' || menuItem.name !== 'matches' || menuItem.name !== 'tables'
                "
                :target="menuItem.link?.includes('http') ? '_blank' : '_self'"
                >{{
                  menuItem.name === "tickets"
                    ? $t(`header.${menuItem.name}`)
                    : footerNavString.includes(menuItem.name)
                      ? $t(`footer.nav.${menuItem.name}`)
                      : $t(`header.navCompetition.${menuItem.name}`)
                }}
              </nuxt-link>
            </li>
          </ul>
        </div>
        <div class="pt-12 md:pt-0 flex justify-start md:justify-end">
          <context-other-leagues-logo height="h-[66px]"></context-other-leagues-logo>
        </div>
      </div>
      <div class="pt-8 text-xs text-gray-500">
        <p>© {{ actualYear }} {{ contextSiteTitle }}. All rights reserved.</p>
      </div>
    </div>
  </footer>
</template>

<style scoped>
.container-items-footer {
  @apply pb-12 border-b border-black gap-x-10 lg:gap-x-24 grid grid-cols-1 items-start md:grid-cols-[384px_auto_114px];
}

.nav-footer {
  @apply text-sm md:columns-3 gap-x-8 md:max-h-full max-w-max;
}

.nav-footer li {
  @apply lg:w-32 xl:w-44 mb-4;
}
</style>
